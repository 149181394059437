// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dailyEntries-title-div {
  display: flex;
  justify-content: center;
  padding: 5vh 0;
}

.dailyEntries-title {
  font-size: 30px;
  font-weight: 500;
}

.dailyEntries-table-div {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
  margin-inline: 5%;
}

/*LARGE*/
@media screen and (min-width: 768px) {
  .home-title {
    font-size: 30px;
    font-weight: 500;
  }

  .dailyEntries-table-div {
    width: 70%;
    margin-inline: 15%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/DailyEntries/dailyEntries.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA,QAAQ;AACR;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,UAAU;IACV,kBAAkB;EACpB;AACF","sourcesContent":[".dailyEntries-title-div {\n  display: flex;\n  justify-content: center;\n  padding: 5vh 0;\n}\n\n.dailyEntries-title {\n  font-size: 30px;\n  font-weight: 500;\n}\n\n.dailyEntries-table-div {\n  width: 90%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 5vh;\n  margin-inline: 5%;\n}\n\n/*LARGE*/\n@media screen and (min-width: 768px) {\n  .home-title {\n    font-size: 30px;\n    font-weight: 500;\n  }\n\n  .dailyEntries-table-div {\n    width: 70%;\n    margin-inline: 15%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
