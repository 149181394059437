import WinnerTableRow from "./WinnerTableRow";

function WinnerTableBody({ data }) {
  return (
    <>
      {data.map((row) => {
        return <WinnerTableRow key={row.entry} data={row} />;
      })}
    </>
  );
}

export default WinnerTableBody;
