import { Typography } from "@mui/material";
import { useGetDailyEntriesQuery } from "Services/FunExtreme_Apis";
import ErrorBox from "Components/ErrorBox";
import { feedbackMsg } from "Utils/Data";

function WithDailyEntries(OldComponent) {
  function NewComponent() {
    const {
      isLoading: isLoadingGetDailyEntriesAPI,
      isSuccess: isSuccessGetDailyEntriesAPI,
      data: getDailyEntriesAPIResponse,
      error: getDailyEntriesAPIError,
      refetch: refetchDailyEntriesAPI,
    } = useGetDailyEntriesQuery();

    let apiContent;

    if (isLoadingGetDailyEntriesAPI) {
      apiContent = <Typography>{feedbackMsg.loadingMsg}</Typography>;
    } else if (getDailyEntriesAPIError) {
      apiContent = (
        <ErrorBox
          msg={feedbackMsg.errorMsg}
          callbackFn={refetchDailyEntriesAPI}
        />
      );
    } else if (isSuccessGetDailyEntriesAPI) {
      if (getDailyEntriesAPIResponse.entriesCount?.length === 0) {
        apiContent = <Typography>{feedbackMsg.noRecordsMsg}</Typography>;
      }
    }

    return (
      <OldComponent
        data={getDailyEntriesAPIResponse?.entriesCount}
        feedbackContent={apiContent}
      />
    );
  }
  return NewComponent;
}

export default WithDailyEntries;
