import { Button, Collapse, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

function AppBarLogo() {
  return (
    <img alt="appbar-logo" src="images/747-logo.png" className="appbar-logo" />
  );
}

function JoinRaffleBtn({ joinRaffleBtnClick }) {
  return (
    <Button
      className="join-raffle-btn"
      aria-label="join-raffle-btn"
      variant="outlined"
      disableElevation
      onClick={joinRaffleBtnClick}
    >
      Join Raffle
    </Button>
  );
}

function DesktopMenu({ pageLst, theme, selectedMenuItem }) {
  return (
    <nav className="appbar-menuItem-div" aria-label="appbar-menuItem-div">
      {pageLst.map((item) => (
        <Button
          key={item.index}
          className="appbar-menuItem"
          onClick={() => item.fn(item.index)}
          sx={{
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            color:
              selectedMenuItem === item.index
                ? theme.palette.highlight
                : "#FFF",
          }}
        >
          {item.name}
        </Button>
      ))}
    </nav>
  );
}

function HamburgerBtn({ hamburgerBtnClick }) {
  return (
    <div className="appbar-hamburger-div" aria-label="appbar-hamburger-div">
      <IconButton sx={{ color: "white" }} onClick={hamburgerBtnClick}>
        <MenuIcon sx={{ width: "5vh", height: "auto", minWidth: "30px" }} />
      </IconButton>
    </div>
  );
}

function MobileMenu({ rootExpanded, pageLst, selectedMenuItem, theme }) {
  return (
    <div
      className="appbar-mobile-menuItem-div"
      aria-label="appbar-mobile-menuItem-div"
    >
      <Collapse in={rootExpanded}>
        <nav
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {pageLst.map((item) => (
            <Button
              key={item.index}
              className="appbar-mobile-menuItem"
              onClick={() => item.fn(item.index)}
              sx={{
                justifyContent: "left",
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                color:
                  selectedMenuItem === item.index
                    ? theme.palette.highlight
                    : "#FFF",
              }}
            >
              {item.name}
            </Button>
          ))}
        </nav>
      </Collapse>
    </div>
  );
}

export { AppBarLogo, JoinRaffleBtn, DesktopMenu, HamburgerBtn, MobileMenu };
