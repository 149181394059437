import { useState, useContext } from "react";
import { Button } from "@mui/material";
import "./spinWheel.css";
import { dataContext } from "Contexts/DataContext";

function SpinWheel() {
  const { iframeRef } = useContext(dataContext);

  const toggleAudio = (state) => {
    iframeRef.current?.contentWindow.setAudio(state);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "block",
        width: "100%",
        height: "80vh",
      }}
    >
      <iframe
        ref={iframeRef}
        id="gameIframe"
        title="gameFrame"
        src="SpinWheel/index.html"
        style={{
          display: "block",
          width: "100%",
          height: "80vh",
          border: "0",
          top: "0",
          left: "0",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      />
      <div
        style={{
          position: "absolute",
          opacity: 0,
          width: "100%",
          height: "80vh",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          zIndex: 2,
        }}
      />

      <AudioButtonToggle toggleAudioFn={toggleAudio} />
    </div>
  );
}

export default SpinWheel;

function AudioButtonToggle({ toggleAudioFn }) {
  const [isPlaying, setIsPlaying] = useState(true);

  const audioToggleBtnClick = () => {
    setIsPlaying((prevState) => !prevState);
    toggleAudioFn(isPlaying);
  };

  return (
    <div className="spinwheel-audio-toggle-button-container">
      <Button
        aria-label="Mute volume"
        className="spinwheel-audio-toggle-button"
        onClick={audioToggleBtnClick}
      >
        <div className={`button-icon ${isPlaying ? "pause" : "play"}`} />
      </Button>
    </div>
  );
}
