// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #ececec;
}

.footer-div p {
  font-size: 1rem;
  text-align: center;
}

/*LARGE*/
@media screen and (min-width: 768px) {
  .footer-div p {
    font-size: 1.2rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CustomFooter/footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA,QAAQ;AACR;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".footer-div {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 60px;\n  background-color: #ececec;\n}\n\n.footer-div p {\n  font-size: 1rem;\n  text-align: center;\n}\n\n/*LARGE*/\n@media screen and (min-width: 768px) {\n  .footer-div p {\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
