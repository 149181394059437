import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const imiApi = createApi({
  reducerPath: "imiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_IMI_BASE_URL,
  }),
  endpoints: (builder) => ({
    getDynamicData: builder.query({
      query: () => ({
        url: "/lgo/game/data/4bdfe380-202c-428a-b25a-b8ac5f43b69f",
        method: "GET",
        headers: {},
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const { useGetDynamicDataQuery } = imiApi;
export default imiApi;
