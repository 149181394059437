import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const funExtremeApi = createApi({
  reducerPath: "funExtremeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FUNEXTREME_BASE_URL,
  }),
  endpoints: (builder) => ({
    getWinners: builder.mutation({
      query: ({ pageNumber, raffleID }) => ({
        url: `/draw/winner?raffleId=${raffleID}&page=${pageNumber}`,
        method: "POST",
        headers: {},
      }),
      transformResponse: (response) => response,
    }),
    searchWinner: builder.mutation({
      query: ({ socialCode, pageNumber, raffleID }) => ({
        url: `/draw/winner?raffleId=${raffleID}&page=${pageNumber}&socialCode=${socialCode}`,
        method: "POST",
        headers: {},
      }),
      transformResponse: (response) => response,
    }),
    getDailyEntries: builder.query({
      query: () => ({
        url: "/Raffle/GetCountOfEntries",
        method: "GET",
        headers: {},
      }),
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useGetWinnersMutation,
  useSearchWinnerMutation,
  useGetDailyEntriesQuery,
} = funExtremeApi;
export default funExtremeApi;
