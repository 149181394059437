import { AppBar, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./appbar.css";
import { homeActions } from "Services/Store";
import { IframeEvents } from "Utils/Data";
import { getInitRouteIndex, getPageLst } from "./UtilFns";
import {
  AppBarLogo,
  DesktopMenu,
  HamburgerBtn,
  JoinRaffleBtn,
  MobileMenu,
} from "./UtilComponents";

function CustomAppBar() {
  // #region HOOKS
  const theme = useTheme();
  const location = useLocation();

  const [selectedMenuItem, setSelectedMenuItem] = useState(
    getInitRouteIndex(location.pathname),
  );
  const [rootExpanded, setRootExpanded] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // JS BRIDGE
  useEffect(() => {
    const msgEventHandler = (event) => {
      if (typeof event.data === "string") {
        // CHECK FOR JSON OBJECT
        try {
          const commandObj = JSON.parse(event.data);

          if (commandObj.command === "FETCH_WINNERS") {
            // FETCH WINNER LST
            dispatch(homeActions.setIframeEvent(IframeEvents.fetchWinner));
          } else if (commandObj.command === "ADD_WINNER") {
            // ADD WINNER TO THE TABLE
            dispatch(homeActions.setIframeEvent(IframeEvents.addWinner));
          }
        } catch (e) {
          console.log(`JSON Parse Error ${e}`);
        }
      }
    };

    window.addEventListener("message", msgEventHandler);

    return () => window.removeEventListener("message", msgEventHandler);
  }, []);
  // #endregion

  // #region INIT DATA
  const pageLst = getPageLst(navigate, setSelectedMenuItem);
  // #endregion

  // #region UTIL FNS

  function joinRaffleBtnClick() {
    window.open("https://dashboard.747raffle.com/", "_blank");
  }

  function hamburgerBtnClick() {
    setRootExpanded(!rootExpanded);
  }
  // #endregion

  return (
    <AppBar position="static" sx={{ boxShadow: "none" }}>
      <div className="appbar-main-div">
        <AppBarLogo />
        <div style={{ display: "flex", alignItems: "center" }}>
          <JoinRaffleBtn joinRaffleBtnClick={joinRaffleBtnClick} />

          <DesktopMenu
            pageLst={pageLst}
            theme={theme}
            selectedMenuItem={selectedMenuItem}
          />

          <HamburgerBtn hamburgerBtnClick={hamburgerBtnClick} />
        </div>
      </div>

      <MobileMenu
        rootExpanded={rootExpanded}
        pageLst={pageLst}
        selectedMenuItem={selectedMenuItem}
        theme={theme}
      />
    </AppBar>
  );
}

export default CustomAppBar;
