import {
  Box,
  Button,
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import Select from "@mui/material/Select";
import { useEffect, useState, useContext } from "react";
import { dataContext } from "Contexts/DataContext";
import {
  useGetWinnersMutation,
  useSearchWinnerMutation,
} from "Services/FunExtreme_Apis";
import { feedbackMsg } from "Utils/Data";
import ErrorBox from "Components/ErrorBox";
import WinnerTableBody from "Components/WinnerTable/WinnerTableBody";
import WinnerTableHeader from "Components/WinnerTable/WinnerTableHeader";

function PastWinnersInfo() {
  // #region HOOKS
  const [searchText, setSearchText] = useState("");

  const [apiStateIndex, setApiStateIndex] = useState(0);

  const raffleIDList = useContext(dataContext).RaffleIDList;

  const [currentRaffleID, setCurrentRaffleID] = useState(raffleIDList[0].id);

  const initPageNumber = 1;
  useEffect(() => {
    getWinnersAPI({ pageNumber: initPageNumber, raffleID: currentRaffleID });
  }, []);

  // #endregion

  // #region APIS

  // GET WINNERS API
  const [
    getWinnersAPI,
    {
      isLoading: isLoadingGetWinnersAPI,
      isSuccess: isSuccessGetWinnersAPI,
      data: getWinnersAPIResponse,
      error: getWinnersAPIError,
    },
  ] = useGetWinnersMutation();

  // SEARCH WINNER API
  const [
    searchWinnerAPI,
    {
      isLoading: isLoadingSearchWinnerAPI,
      isSuccess: isSuccessSearchWinnerAPI,
      data: searchWinnerAPIResponse,
      error: searchWinnerAPIError,
    },
  ] = useSearchWinnerMutation();
  // #endregion

  // #region SEARCH COMPONENT & DROPDOWN

  function changeSearchText(event) {
    setSearchText(event.target.value);
  }

  const handleChangeRaffleID = (event) => {
    setCurrentRaffleID(event.target.value);
    fetchWinnerLst(event.target.value);
  };

  function searchBtnClick() {
    fetchSearchWinnerLst();
  }

  function searchClearBtnClick() {
    setApiStateIndex(0);
    setSearchText("");
  }

  const searchComponent = (
    <div className="home-search-div">
      <TextField
        value={searchText}
        onChange={changeSearchText}
        placeholder="Social Code"
        className="home-search-textfield"
        inputProps={{
          style: {
            height: "15px",
          },
        }}
        sx={{
          "& fieldset": {
            borderRadius: "10px",
          },
        }}
      />
      <Button
        variant="contained"
        disableElevation
        className="home-search-btn"
        onClick={searchBtnClick}
      >
        Search
      </Button>

      {apiStateIndex === 1 && (
        <Button
          variant="contained"
          disableElevation
          className="home-search-clear-btn"
          onClick={searchClearBtnClick}
        >
          Clear
        </Button>
      )}
    </div>
  );

  const raffleIDDropDownList =
    raffleIDList.length > 0 ? (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormControl>
          <InputLabel>Type</InputLabel>
          <Select
            value={currentRaffleID}
            label="Raffle ID"
            onChange={handleChangeRaffleID}
          >
            {raffleIDList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    ) : null;

  const midSection = (
    <Box className="midSection-container">
      {searchComponent}
      {raffleIDDropDownList}
    </Box>
  );

  // #endregion

  // #region TABLE COMPONENT

  function fetchWinnerLst(raffleId) {
    getWinnersAPI({ pageNumber: initPageNumber, raffleID: raffleId });
    setApiStateIndex(0);

    setSearchText("");
  }

  function fetchSearchWinnerLst() {
    if (!searchText) return;

    searchWinnerAPI({
      pageNumber: initPageNumber,
      socialCode: searchText,
      raffleID: currentRaffleID,
    });
    setApiStateIndex(1);
  }

  const handlePageChange = (event, value) => {
    if (apiStateIndex === 0) {
      getWinnersAPI({ pageNumber: value, raffleID: currentRaffleID });
    } else if (apiStateIndex === 1) {
      searchWinnerAPI({
        pageNumber: value,
        socialCode: searchText,
        raffleID: currentRaffleID,
      });
    }
  };

  let tableBodyContent;
  let tableFeedbackContent;

  let pageNumber;
  let pageCount;

  function getWinnersAPIStatesMangeFn() {
    if (isLoadingGetWinnersAPI) {
      tableFeedbackContent = <Typography>{feedbackMsg.loadingMsg}</Typography>;
    } else if (getWinnersAPIError) {
      tableFeedbackContent = (
        <ErrorBox
          msg={feedbackMsg.errorMsg}
          callbackFn={() => fetchWinnerLst(currentRaffleID)}
        />
      );
    } else if (isSuccessGetWinnersAPI) {
      const winnersLst = getWinnersAPIResponse.winners;

      if (winnersLst.length > 0) {
        pageNumber = getWinnersAPIResponse.currentPage;
        pageCount = getWinnersAPIResponse.totalPages;

        tableBodyContent = <WinnerTableBody data={winnersLst} />;
      } else {
        tableFeedbackContent = (
          <Typography>{feedbackMsg.noRecordsMsg}</Typography>
        );
      }
    }
  }

  function searchWinnersAPIStatesMangeFn() {
    if (isLoadingSearchWinnerAPI) {
      tableFeedbackContent = <Typography>{feedbackMsg.loadingMsg}</Typography>;
    } else if (searchWinnerAPIError) {
      tableFeedbackContent = (
        <ErrorBox
          msg={feedbackMsg.errorMsg}
          callbackFn={fetchSearchWinnerLst}
        />
      );
    } else if (isSuccessSearchWinnerAPI) {
      const searchWinnersLst = searchWinnerAPIResponse.winners;

      if (searchWinnersLst.length > 0) {
        pageNumber = searchWinnerAPIResponse.currentPage;
        pageCount = searchWinnerAPIResponse.totalPages;

        tableBodyContent = <WinnerTableBody data={searchWinnersLst} />;
      } else {
        tableFeedbackContent = (
          <Typography>{feedbackMsg.noRecordsMsg}</Typography>
        );
      }
    }
  }

  if (apiStateIndex === 0) {
    // GET WINNERS API
    getWinnersAPIStatesMangeFn();
  } else if (apiStateIndex === 1) {
    // SEARCH WINNERS API
    searchWinnersAPIStatesMangeFn();
  }

  // #endregion

  return (
    <>
      <Box className="home-title-div">
        <Typography className="home-title">Winners</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        {midSection}
      </Box>

      <div className="home-table-div">
        <TableContainer>
          <Table stickyHeader>
            <WinnerTableHeader />
            <TableBody>{tableBodyContent}</TableBody>
          </Table>
        </TableContainer>
      </div>
      {tableFeedbackContent && (
        <div
          style={{ display: "flex", justifyContent: "center", margin: "2vh 0" }}
        >
          {tableFeedbackContent}
        </div>
      )}

      {pageCount > 1 && (
        <Pagination
          sx={{ display: "flex", justifyContent: "right", margin: "3vh 5%" }}
          count={pageCount}
          page={pageNumber}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          size="small"
        />
      )}
    </>
  );
}

export default PastWinnersInfo;
