import SpinWheel from "Components/SpinWheel";
import WinMessage from "Components/WinMessage";
import { pageNames } from "Utils/Data";
import WinnersInfo from "./WinnersInfo";

function HomePage() {
  return (
    <div style={{ minHeight: "100vh", whiteSpace: "pre-wrap" }}>
      <SpinWheel />
      <WinMessage pageName={pageNames.HOME} />
      <WinnersInfo />
    </div>
  );
}

export default HomePage;
