// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.win-message-text {
  box-sizing: border-box;
  text-align: center;
  padding-inline: 2%;
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.win-message-text p {
  color: white;
  display: inline-block;
  font-size: 1.3rem;
  padding: 2vh 0;
  /* padding: 2vh 0 2vh 100%; */
  /* animation: marquee 20s linear infinite; */
}

/* @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }  */
`, "",{"version":3,"sources":["webpack://./src/Components/WinMessage/winMessage.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;EACd,6BAA6B;EAC7B,4CAA4C;AAC9C;;AAEA;;;;;;;MAOM","sourcesContent":[".win-message-text {\n  box-sizing: border-box;\n  text-align: center;\n  padding-inline: 2%;\n  /* white-space: nowrap; */\n  /* overflow: hidden; */\n}\n\n.win-message-text p {\n  color: white;\n  display: inline-block;\n  font-size: 1.3rem;\n  padding: 2vh 0;\n  /* padding: 2vh 0 2vh 100%; */\n  /* animation: marquee 20s linear infinite; */\n}\n\n/* @keyframes marquee {\n    0% {\n      transform: translate(0, 0);\n    }\n    100% {\n      transform: translate(-100%, 0);\n    }\n  }  */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
